#Crash {

  #TryAgain {
    width: 100%;
    .izo-button {
      flex: 0 1 32%;
      background: izo-color('ozone-primary');
      .izo-image {
        height: 16px;
        width: 16px;
      }
    }
  }
}