#Login {
  flex-grow: 1;

  #Login-image {
    height: 15vh;
    width: 15vh;
    border: 4.79452px solid izo-color('grey-500');
    box-sizing: border-box;
    border-radius: 50%;

    .izo-image {
      height: 32%;
      width: 32%;
    }

    #Login-svg {
      fill: izo-color('grey-300')
    }
  }

  #Login-button {
    width: 300px;
    background: izo-color('grey-500');
    color: izo-color('white');
  }
}

#SubText {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  color: izo-color('grey-300')
}

#HeaderText{
  font-size: 30px;
  color: izo-color('grey-500');
}
