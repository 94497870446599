#App-fallback {
    height: 100%;
    background: #1f1f1f;
    justify-content: space-between;
}

#Crash-oops-svg {
    #Oval, #Oval-Copy-12, #oops {
        fill: izo-color('white');
    }
    g {
        stroke: izo-color('white')
    }
}