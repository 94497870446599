#AuthCustomLogin {
    height: 100%;
    background: #1f1f1f;
  }
  
  #Login {
    #HeaderText {
      color: izo-color('white');
    }
  
    #Login-button {
      background: izo-color('white');
      color: #1f1f1f;
      box-shadow: rgb(255 255 255 / 25%) 0px 0px 30px;
    }
  
    #Login-image {
      border: izo-color('white') 5px solid;
      box-shadow: rgb(255 255 255 / 25%) 0px 0px 30px;
  
      #Login-svg {
        fill: izo-color('white');
      }
    }
  }