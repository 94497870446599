#EmailOptIn {
    height: 100%;
    flex-grow: 1;
    #EmailOptIn-icon {
      fill: izo-color('white');
    }
    #EmailOptIn-header {
      @include mobile {
        width: 80%;
      }
      color: izo-color('white');
    }
    #EmailOptIn-button {
      background: izo-color('white');
      box-shadow: rgb(255 255 255 / 25%) 0px 0px 30px;
      color: #1f1f1f;
    }
    #EmailOptIn-checkbox-input {
      background-color: izo-color('white', 0.25);
    }
}

#EmailOptIn #EmailOptIn-checkbox > .izo-text {
  @include mobile {
    width: 60%;
  }
}