#AuthCustomLoading {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    #Footer {
      background-color: #1f1f1f;
    }
  }
  