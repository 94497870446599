.izo-input {
  position: relative;
  flex: 0 1 100%;
  overflow: hidden;
  border-radius: 8px;
  font-family: var(--font-body);

  label {
    position: absolute;
    top: 11px;
    left: 15px;
    transition: all 0.1s ease;
    font-weight: 400;
    font-size: 14px;
    color: izo-color('white');
    pointer-events: none;
    font-family: var(--font-header);
  }

  input {
    width: 100%;
    border: none;
    padding: 12px 12px 4px 12px;
    font-size: 14px;
    transition: border 0.2s ease;
    background: izo-color('white');
    border-radius: 8px;
    color: izo-color('black');
    border: 3px solid izo-color('transparent');

    &:focus {
      border: 3px solid izo-color('blue');
      outline: none;
    }
  }

  &.error input {
    border: 3px solid izo-color('red');
  }

  &.validated input {
    border: 3px solid izo-color('teal');
  }

  input:focus + label,
  textarea:focus + label,
  &:not(.empty) label {
    color: izo-color('blue');
    font-size: 8px;
    top: 4px;
    font-weight: 900;
  }

  &.error {
    label {
      color: izo-color('red') !important;
    }
  }
}
