footer#Footer {
    background-color: rgba(255, 255, 255, 0.3);
    border-top: izo-color('white') 1px solid;
    box-shadow: rgb(255 255 255 / 25%) 0px 0px 30px;
  
    #Footer-right {
      .izo-external-link {
        color: inherit;
      }
    }
  }
  