.izo-button {
  font-family: var(--font-header);
  font-weight: 600;
  cursor: pointer;
  border-radius: 64px;
  transition: all 0.1s ease;
  position: relative;
  border: none;
  white-space: nowrap;
  max-width: 100%;
  @include shadow('medium');

  @include hover {
    transform: translateY(-1px);
    @include shadow('large');
  }

  @include active {
    transform: translateY(0px);
    @include shadow('small');
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.full-width {
    width: 100%;
  }

  &.size-small {
    font-size: 12px;
    padding: 4px 8px;
  }

  &.size-medium {
    font-size: clamp(12px, 1.2vw, 20px);
    padding: 16px 32px;

    @include mobile {
      font-size: 14px;
      padding: 8px 16px;
    }
  }

  &.size-large {
    font-size: clamp(16px, 1.6vw, 24px);
    font-weight: 700;
    padding: 18px 36px;

    @include mobile {
      font-size: 18px;
      font-weight: 700;
      padding: 12px 24px;
    }
  }

  &.type-solid {
    &.color-white {
      background: izo-color('white');
      color: izo-color('black');
    }
    &.color-black {
      background: izo-color('black');
      color: izo-color('white');
    }

    &.color-blue {
      background: izo-color('blue');
      color: izo-color('white');
    }

    &.color-red {
      background: izo-color('red');
      color: izo-color('white');
    }

    &.color-grey {
      background: izo-color('grey-200');
      color: izo-color('white');
    }
  }

  &.type-outline {
    $borderWidth: 2px;
    $borderStyle: solid;
    background: izo-color('transparent');

    &.color-white {
      color: izo-color('white');
      border: $borderWidth $borderStyle izo-color('white');
    }
    &.color-black {
      color: izo-color('black');
      border: $borderWidth $borderStyle izo-color('black');
    }

    &.color-blue {
      color: izo-color('blue');
      border: $borderWidth $borderStyle izo-color('blue');
    }

    &.color-red {
      color: izo-color('red');
      border: $borderWidth $borderStyle izo-color('red');
    }

    &.color-grey {
      color: izo-color('grey-200');
      border: $borderWidth $borderStyle izo-color('grey-200');
    }
  }

  &.type-text {
    background: var(--izo-transparent);
    box-shadow: none;
    font-weight: 600;
    padding: 0;

    @include hover {
      transform: none;
      box-shadow: none;
    }

    @include active {
      transform: none;
      box-shadow: none;
    }

    &.color-white {
      color: izo-color('white');
    }

    &.color-black {
      color: izo-color('black');
    }

    &.color-blue {
      color: izo-color('blue');
    }

    &.color-red {
      color: izo-color('red');
    }

    &.color-grey {
      color: izo-color('grey-200');
    }
  }
}
