header#Header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  overflow: hidden;
  padding: 16px 32px;
  flex-shrink: 0;

  @include mobile {
    padding: 16px;
  }

  #Header-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .izo-image {
      height: 48px;
      width: 48px;
    }

    span {
      vertical-align: top;
      font-size: 10px;
      padding: 4px 8px;
      border-radius: 16px;
      margin-left: 8px;
      background: izo-color('ozone-primary');
    }

    @include mobile {
      .secondary-header {
        font-size: 24px;
      }
    }
  }

  #Header-startover {
    background-color: transparent;
    display: flex;
    padding: 0px;
    align-items: center;
  }

  #Header-logotext {
    font-size: 20px;
  }

  #Header-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .izo-image {
      height: 32px;
      width: 32px;
      background: izo-color('black');
      border-radius: 50%;
      object-fit: cover;
    }
    #Header-right-content {
      flex: 1 0 auto;
      .paragraph {
        font-size: 18px;
        font-weight: 600;
        font-family: var(--font-header);
      }
      .izo-button {
        font-weight: 400;
      }
      * {
        flex: 1 0 auto;
      }
    }
    @include mobile {
      #Header-right-content {
        display: none;
      }
    }
  }
}

#SignOut-Button {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  text-decoration: underline;
}
