footer#Footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  overflow: hidden;
  padding: 24px 32px;
  flex-shrink: 0;

  @include mobile {
    padding: 16px;
  }

  #Footer-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .izo-image {
      height: 48px;
      object-fit: contain;

      @include mobile {
        height: 32px;
      }
    }
  }

  #Footer-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .izo-external-link {
      color: izo-color('white');
    }
    @include mobile {
      flex-wrap: wrap;
      justify-content: flex-end;
      .small {
        width: 100%;
        text-align: right;
      }
    }
  }
}
