#izo-modal-background {
  position: fixed;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  @include fade-in;

  .izo-modal {
    position: relative;
    width: 92vw;
    max-width: 600px;
    padding: 32px;
    background: var(--izo-white);
    border-radius: 8px;
    box-shadow: var(--izo-shadow-large);
    @include move-in;

    @include mobile {
      padding: 24px 16px;
    }
  }
}
