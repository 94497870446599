#SetVibe {
  margin: 20px 20px 0 20px;
  @include mobile {
    margin: 10px 10px 0px 10px;
  }
}

#SetVibe.authed {
  margin: 20px 20px 20px 20px;
  @include mobile {
    margin: 10px 10px 10px 10px;
  }
}

.card.card-active.setvibe.izo-button {
  display: flex;
  flex-direction: column;
  margin: 8px;
  @include mobile {
    margin: 5px;
  }
  height: 108px;
  &.mix-it-up {
    height: 72px;
  }
  width: 100%;
  justify-content: center;
  align-items: center;
  .izo-text {
    font-size: 20px;
    font-family: 'kallisto';
    font-weight: 700;
    color: white;
  }
  .izo-text.small {
    font-size: 10px;
    font-family: 'Roboto';
    margin: 1%;
    opacity: 0.8;
  }
  .izo-image {
    height: 25%;
    margin: 2%;
  }
  background-color: rgba(255, 255, 255, 0.3);
}

.card.card-active.setvibe.selected {
  background-color: rgba(255, 255, 255, 0.4);
  border: 2px solid white;
}

.setvibe-top {
  width: 100%;
}

#SetVibeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
