.izo-text {
  position: relative;
  text-align: inherit;

  &.primary-header {
    font-size: clamp(36px, 4vw, 50px);
    line-height: 1.3em;
    font-weight: 900;
    font-family: var(--font-header);

    @include mobile {
      font-size: 32px;
    }
  }

  &.secondary-header {
    font-size: clamp(24px, 3vw, 36px);
    line-height: 1.3em;
    font-weight: 700;
    font-family: var(--font-header);

    @include mobile {
      font-size: 28px;
      line-height: 30px;
    }
  }

  &.section-header {
    font-size: clamp(20px, 2vw, 32px);
    line-height: 1.3em;
    font-weight: 700;
    font-family: var(--font-header);

    @include mobile {
      font-size: 24px;
    }
  }

  &.sub-header {
    font-size: clamp(14px, 1.3vw, 20px);
    line-height: 1.6em;
    font-weight: 500;
    font-family: var(--font-header);

    @include mobile {
      font-size: 14px;
    }
  }

  &.small-header {
    font-size: clamp(16px, 1.5vw, 24px);
    line-height: 1.5em;
    letter-spacing: 0.05em;
    font-weight: 700;
    font-family: var(--font-header);

    @include mobile {
      font-size: 14px;
    }
  }

  &.paragraph {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    font-family: var(--font-body);
  }

  &.small {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    font-family: var(--font-body);
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.no-wrap {
    @include ellipsis;
  }

  &.shadow {
    @include shadow;
  }

  &.white {
    color: izo-color('white');
  }

  &.black {
    color: izo-color('black');
  }

  &.grey {
    color: izo-color('grey-200');
  }

  &.blue {
    color: izo-color('blue');
  }

  &.red {
    color: izo-color('red');
  }
}
