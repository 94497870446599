header#Header {
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 30px;
  z-index: 300;
  overflow: visible;
  justify-content: center;
  min-height: 85px;

  #Header-left {
    position: fixed;
    left: 24px;
    .izo-image {
      height: 24px !important;
    }
  }

  #Header-right {
    #User-id-badge {
      border: izo-color('white') 1px solid;
    }
    @include mobile {
      #Header-right-content.open {
        display: flex;
      }
    }
    position: fixed;
    right: 32px;
    #Header-right-content {
      .paragraph {
        font-size: 20px;
      }
    }
    .izo-button {
      border: 1px transparent;
    }
  }
}

#Header-logotext {
  @media (max-width: 600px) {
    display: none;
  }
}

#Header-center {
  justify-content: center;
  .izo-button {
    color: izo-color('white');
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid white;
  }
  .izo-text {
    font-family: 'kallisto';
    font-weight: 900;
    font-size: 16px;
    color: izo-color('white');
  }
}

#SignOut-Button {
  color: #fff;
}
