@import '@izocloud/ui/dist/index.scss';
@import './transitions.scss';
@import './Header.scss';
@import './Footer.scss';
@import './Crash.scss';
@import './EmailOptIn.scss';
@import './Login.scss';
@import './Dropdown.scss';
@import './AuthCustomLoading.scss';
@import './SetVibe.scss';

:root {
  --font-header: 'kallisto';
}

body {
  height: 100vh;
  font-family: 'kallisto';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#__next {
  height: 100%;
}

#AuthCustomLogin {
  height: 100%;
  background: #1f1f1f;
}

#SubText {
  color: #959595;
}

#App-container {
  height: 100%;
  background: #1f1f1f;
}

.izo-button {
  @include hover {
    transform: none;
  }
}

#TOS-container {
  width: 100%;
  padding: 0px 10px 10px 10px;

  a {
    color: white;
    opacity: 0.5;
    font-family: 'Roboto';
    font-weight: 800;
  }
}

#Download-smalltext {
  opacity: 0.8;
}
