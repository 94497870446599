#Dropdown-button {
  position: fixed;
  top: 13px;
  right: 32px;
  height: 60px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;

  @include hover {
    cursor: pointer;
  }

  #Dropdown-tos {
    padding: 16px 8px 16px 8px;
    color: black;
    background-color: #f3f3f3;
    margin-top: 20px;

    button {
      border-radius: 0px;
    }

    a {
      font-size: 12px;
      color: #888888;
      font-family: 'Roboto';
      font-weight: 800;
    }
  }

  #dropdown-hr {
    width: 100%;
    transition: all 0.2s ease-in-out;
    border: none; //Believe it or not this is actually critical for it to look how it's supposed to
    color: #dcdcdc;
  }

  #Dropdown-button-content {
    padding: 12px 24px;
    height: 100%;
  }
}

#Dropdown-button #Dropdown-button-content.closed {
  padding: 0;
}

#dropdown-hr.closed {
  display: none;
}

#dropdown-hr.open {
  display: block;
}

#Dropdown-wrapper #Dropdown-button.Open {
  height: 240px;
  background-color: izo-color('white');

  .izo-text.white {
    color: #1f1f1f;
  }

  #SignOut-Button {
    color: #1f1f1f;
  }
}

#Dropdown-button.Closed {
  height: 60px;
  max-width: 100px;
  transition: none;
}

#Dropdown-button.Open {
  max-width: 700px;
}

#dropdown1.open {
  display: flex;
  flex-direction: column;
}
#dropdown1.closed {
  display: none;
}

#dropdown1 {
  position: relative;
  width: 100%;

  #modal-wrapper {
    padding: 20px 0;
    z-index: 3;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
    padding: 10px 20px;

    .izo-button.type-text.color-white {
      color: #1f1f1f;
      padding: 5px;
      width: 100%;
      border-radius: 5px;

      .izo-image {
        border-radius: 0;
        background: transparent;
        align-items: flex-end;
      }
    }
  }

  .modal-list-item {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .izo-image {
      margin-left: 10px;
    }
  }
}
