@import url(./ball-spin-clockwise.css);

$full-card-height: 80px;
$full-card-height-desktop: 96px;

#root {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.App {
  text-align: center;
  height: 100%;
  button {
    z-index: 2;
  }
}

.new-gradient {
  position: absolute;
  z-index: 0;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.new-gradient.last-gradient-fade {
  opacity: 0;
  transition: none;
}

.old-gradient {
  position: absolute;
  z-index: -3;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.looper-footer button {
  box-shadow: rgb(255 255 255 / 25%) 0px 0px 30px;
  outline: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loop-track-transition-enter {
  height: 0px;
}

.loop-track-transition-enter-active {
  height: $full-card-height;
  @media (min-width: 600px) {
    height: $full-card-height-desktop;
  }

  transition: all 0.2s ease;
}

.loop-track-transition-enter-done {
  height: auto;
}

.loop-track-transition-exit {
  opacity: 1;
}

.loop-track-transition-exit-active {
  opacity: 0;
  transition: all 100ms ease;
}

.loop-track-transition-exit-done {
  opacity: 0;
  height: 0px;
}

.looper-container {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}

$header-footer-height: 76px;

.looper-footer {
  flex-grow: 0;
  flex-shrink: 0;
  height: $header-footer-height;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.looper-footer.authed {
  margin-bottom: 24px;
}

.looper-track-container {
  flex-grow: 1;
  transition: position 0.2s ease;
  height: 55vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  margin-top: 50px;
}

.card {
  height: $full-card-height;
  @media (min-width: 600px) {
    height: $full-card-height-desktop;
  }
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  background-repeat: no-repeat;
  background-position: center;
  transition: height 0.5s ease, opacity 0.2s ease;
  border: #fff 1px solid;
}

.card-melodic,
.card-bass,
.card-drums,
.card-percussion {
  display: flex;
  padding: 30px;
  @include mobile {
    padding: 24px;
  }
}

.card.card-mute {
  opacity: 0.5;
}

.card.card-mute.disabled {
  cursor: default;
}

.card.card-active {
  height: $full-card-height;
  @media (min-width: 600px) {
    height: $full-card-height-desktop;
  }
  border: #fff 1px solid;
  box-shadow: rgb(255 255 255 / 25%) 0px 0px 30px;
}

@mixin utility-button {
  border: none;
  background: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: calc(76px / 2);
  transition: opacity 0.2s ease;
}

.utility-container {
  position: relative;
  width: 76px;
  height: 76px;
}

@mixin big-utility-button {
  @include utility-button;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
}

@mixin small-utility-button {
  @include utility-button;
  width: 48px;
  height: 48px;
  border-radius: calc(48px / 2);
}

@mixin utility-button-active {
  opacity: 0.5;
}

$small-button-spacing: 32px;

.global-mute-button {
  margin-right: $small-button-spacing;
  @include small-utility-button;
  padding: 0px 0px !important;
  background-size: contain;
}

.global-mute-button:active {
  @include utility-button-active;
}

.card-track-wrapper {
  margin-top: 10px;
}

.download-button {
  @include big-utility-button;
  padding: 0px 0px !important;
  background-size: contain;
}

.download-button:active {
  @include utility-button-active;
}

.add-track-button:active {
  @include utility-button-active;
}

.close-share-button {
  @include big-utility-button;
  padding: 0px 0px !important;
  background-size: contain;
}

.close-share-button:active {
  @include utility-button-active;
}

.start-engine-button,
.add-track-button {
  @include big-utility-button;
  padding: 0px 0px !important;
  border: #fff 1px solid;
  background-size: contain;
}

.start-engine-button:active {
  @include utility-button-active;
}

.start-preloaded-engine-button {
  @include big-utility-button;
  padding: 0px 0px !important;
  background-size: contain;
}

.start-preloaded-engine-button:active {
  @include utility-button-active;
}

.reset-button {
  margin-left: $small-button-spacing;
  @include small-utility-button;
  padding: 0px 0px !important;
  background-size: contain;
}

.reset-button:active {
  @include utility-button-active;
}

.intro-text {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30vh;
  @include mobile {
    margin-top: 15vh;
  }
}

.intro-text h1 {
  margin: 0px;
  margin-bottom: 4px;
  font-size: 48px;
  font-weight: 900;
}

.intro-text p {
  margin: 0px;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  opacity: 0.8;
}

.loading-indicator {
  @include big-utility-button;
  background-image: url(../public/ic_loading.svg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.visualizer {
  height: 48px;
  flex-shrink: 1;
  z-index: 2;
}

.looper-share-slot {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 75%;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  > .izo-button {
    border-radius: 0;
  }
}

.looper-share-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.looper-share-wrapper {
  width: 100%;
}
