@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://use.typekit.net/yvk2fve.css');

:root {
  --font-header: 'Korolev';
  --font-body: 'Roboto';

  --izo-white: 255, 255, 255; // #FFFFFF;
  --izo-black: 0, 0, 0; // #000000;
  --izo-transparent: 0, 0, 0; // #00000000;

  --izo-grey-100: 241, 243, 245; // #F1F3F5;
  --izo-grey-200: 165, 172, 178; // #A5ACB2;
  --izo-grey-300: 116, 130, 140; // #74828C;
  --izo-grey-400: 86, 96, 104; // #566068;
  --izo-grey-500: 49, 58, 66; // #313A42;
  --izo-grey-600: 30, 36, 41; // #1E2429;
  --izo-grey-700: 19, 22, 26; // #13161A;

  --izo-red: 255, 87, 102; // #FF5766;
  --izo-orange: 255, 96, 28; // #FF601C;
  --izo-yellow: 248, 165, 41; // #F8A529;
  --izo-yellow-green: 196, 232, 107; // #C4E86B;
  --izo-teal: 9, 185, 185; // #09B9B9;
  --izo-light-blue: 169, 221, 228; // #A9DDE4;
  --izo-medium-blue: 9, 178, 222; // #09B2DE;
  --izo-blue: 47, 135, 255; // #2F87FF;
  --izo-purple: 113, 85, 199; // #7155C7;

  --izo-ozone-bg: 24, 52, 67; // #183443;
  --izo-ozone-primary: 9, 178, 222; // #09B2DE;

  --izo-rx-blue: 0, 133, 255; // #0085FF;
  --izo-rx-orange: 247, 164, 38; // #F7A426;
}

// START: SASS mixins and functions

@function izo-color($name: 'black', $opacity: 1) {
  $color: var(--izo-#{$name});
  @if $name == 'transparent' {
    @return rgba($color, 0);
  }
  @return rgba($color, $opacity);
}

@mixin ellipsis {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  min-width: 0 !important;
  display: block !important;
}

@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      &:not(:disabled) {
        @content;
      }
    }
  }
}

@mixin active {
  &:active {
    &:not(:disabled) {
      @content;
    }
  }
}

@mixin mobile {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin shadow($size: 'medium') {
  @if $size == 'small' {
    box-shadow: 0
      clamp(2px, 0.24vw, 4px)
      clamp(2px, 0.24vw, 4px)
      var(--izo-black-transparent);
  }
  @if $size == 'medium' {
    box-shadow: 0
      clamp(4px, 0.8vw, 10px)
      clamp(4px, 0.8vw, 10px)
      var(--izo-black-transparent);
  }
  @if $size == 'large' {
    box-shadow: 0
      clamp(8px, 1.6vw, 16px)
      clamp(8px, 1.6vw, 16px)
      var(--izo-black-transparent);
  }
}

@mixin fade-in {
  opacity: 0;
  animation: fade-in 0.32s ease forwards;

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }
}

@mixin spin {
  animation: spin 4s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

@mixin move-in {
  animation: move-in 0.32s ease forwards;

  @keyframes move-in {
    from {
      transform: translateY(100vw);
    }
    to {
      transform: translateY(0);
    }
  }
}
// END: SASS mixins

@import './Button/Button.scss';
@import './Image/Image.scss';
@import './Input/Input.scss';
@import './Spacer/Spacer.scss';
@import './Text/Text.scss';
@import './ExternalLink/ExternalLink.scss';
@import './Modal/Modal.scss';
@import './FlexContainer/FlexContainer.scss';
@import './EmailOptIn/EmailOptIn.scss';
@import './Header/Header.scss';
@import './Footer/Footer.scss';
@import './Login/Login.scss';
@import './Crash/Crash.scss';
@import './Loading/Loading.scss';