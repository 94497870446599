#EmailOptIn {
  #EmailOptIn-icon {
    fill: izo-color('grey-500');
  }
  #EmailOptIn-header {
    width: 40%;
    color: izo-color('grey-500');
  }
  #EmailOptIn-button {
    width: 300px;
    background: izo-color('grey-500');
    color: izo-color('white');
  }
  #EmailOptIn-checkbox-input{
    margin-top: 3px;
    height: 25px;
    width: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 5px;
    outline: none;
    transition-duration: 0.3s;
    background-color: izo-color('grey-500');
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  #EmailOptIn-checkbox-input:checked {
    background-color: izo-color('grey-300');
  }
  
  #EmailOptIn-checkbox-input:checked::before {
    display: block;
    background-color: izo-color('grey-500');
    height: 10px;
    width: 10px;
    content: '';
    border-radius: 5px;
  }
  #EmailOptIn-checkbox {
    > .izo-text {
      width: 30%
    }
  }
  #EmailOptIn-fine-print {
    font-weight: 300;
    width: 30%;
    .izo-external-link {
      color: izo-color('grey-200');
    }
  }
}
